
















































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { FacetableField, FilterableField } from '@/models/Query';
import { QueryResultFacet } from '@/api/braendz/models/QueryResultFacet';
import { brandStateCategoryColors } from '@/models/BrandStateCategories';

import ConfigureBrandFilterPopup from './ConfigureBrandFilterPopup.vue';
import NiceClassFilterPopup from './NiceClassFilterPopup.vue';
import { GlobalEventType } from '@/models/GlobalEventType';
import { Notification, NotificationSeverity } from '@/models/Notification'

export enum GroupType {
  RegistrationOfficeCodes = "RegistrationOfficeCodes",
  BrandStateCategories = "BrandStateCategories",
  BrandStates = "BrandStates",
  BrandTypes = "BrandTypes",
  Goods = "Goods",
  Services = "Services",
  More = "More",
  Unkown = "Unkown",
}

export enum Comparator {
    Eq = "eq",
    Ne = "ne",
    Lt = "lt",
    Le = "le",
    Gt = "gt",
    Ge = "ge"
}

export interface FilterItem {
    field: FilterableField;
    value: string;
    comparator?: Comparator;
    group?: GroupType;
    title?: string;
    count?: number;
    toolTip?: string;
    icon?: string;
    image?: string;
    color?: string;
    selectedDefault?: boolean;
}

@Component({
    components: {
        ConfigureBrandFilterPopup,
        NiceClassFilterPopup
    },
    model: {
        prop: "model",
        event: "selectedFiltersChanged"
    },
})
export default class BrandFilterTreeView extends Vue {
    
    // Fields
    public configureFilterPopupVisible = false;
    public applicationDateFrom: Date | null = null;
    public applicationDateUntil: Date | null = null;
    public registrationDateFrom: Date | null = null;
    public registrationDateUntil: Date | null = null;
    public oppositionPeriodEndDateFrom: Date | null = null;
    public oppositionPeriodEndDateUntil: Date | null = null;

    public niceClassFilterPopupVisible = false;


    // Component Properties:
    @Prop({ required: true })
    public model!: FilterItem[];

    @Prop({ required: false })
    public facets?: Record<string, QueryResultFacet[]> | null | undefined

    // Getter & Setter
    public get selectedFilters(): FilterItem[] {
        return this.model;
    }
    public set selectedFilters(value: FilterItem[]) {
        this.fireSelectedFiltersChanged(value);
    }

    public get selectedNiceClasses(): FilterItem[] {
        return this.selectedFilters.filter(i => i.field === FilterableField.NiceClasses);
    }
    public set selectedNiceClasses(values: FilterItem[]) {
        const currentlyFiltered = this.selectedFilters.filter(i => i.field === FilterableField.NiceClasses);
        
        for(const removed of currentlyFiltered.filter(i => !values.find(v => this.selectedFilterComparer(i, v)))) {
            const removedIndex = this.selectedFilters.indexOf(removed);
            if(removedIndex >= 0) {
                this.selectedFilters.splice(removedIndex, 1);
            }
        }

        for(const added of values.filter(i => !currentlyFiltered.find(v => this.selectedFilterComparer(i, v)))) {
            this.selectedFilters.push(added);
        }
    }

    public get filters(): FilterItem[] {
        const result: FilterItem[] = [];

        // Add static / default filters:
        result.push(

            // RegistrationOfficeCodes:
            { field: FilterableField.RegistrationOfficeCode, value: 'AT', comparator: Comparator.Eq, group: GroupType.RegistrationOfficeCodes, image: this.$braendz.registrationOfficeCodeFlag('AT') ?? undefined, title: this.$store.getters.getRegistrationOfficeCode('AT')?.shortDescription ?? this.$t('filter.registrationOfficeCode.at'), toolTip: this.$store.getters.getRegistrationOfficeCode('AT')?.description },
            { field: FilterableField.RegistrationOfficeCode, value: 'CH', comparator: Comparator.Eq, group: GroupType.RegistrationOfficeCodes, image: this.$braendz.registrationOfficeCodeFlag('CH') ?? undefined, title: this.$store.getters.getRegistrationOfficeCode('CH')?.shortDescription ?? this.$t('filter.registrationOfficeCode.ch'), toolTip: this.$store.getters.getRegistrationOfficeCode('CH')?.description },
            { field: FilterableField.RegistrationOfficeCode, value: 'DE', comparator: Comparator.Eq, group: GroupType.RegistrationOfficeCodes, image: this.$braendz.registrationOfficeCodeFlag('DE') ?? undefined, title: this.$store.getters.getRegistrationOfficeCode('DE')?.shortDescription ?? this.$t('filter.registrationOfficeCode.de'), toolTip: this.$store.getters.getRegistrationOfficeCode('DE')?.description },
            { field: FilterableField.RegistrationOfficeCode, value: 'EM', comparator: Comparator.Eq, group: GroupType.RegistrationOfficeCodes, image: this.$braendz.registrationOfficeCodeFlag('EM') ?? undefined, title: this.$store.getters.getRegistrationOfficeCode('EM')?.shortDescription ?? this.$t('filter.registrationOfficeCode.em'), toolTip: this.$store.getters.getRegistrationOfficeCode('EM')?.description },
            { field: FilterableField.RegistrationOfficeCode, value: 'ES', comparator: Comparator.Eq, group: GroupType.RegistrationOfficeCodes, image: this.$braendz.registrationOfficeCodeFlag('ES') ?? undefined, title: this.$store.getters.getRegistrationOfficeCode('ES')?.shortDescription ?? this.$t('filter.registrationOfficeCode.es'), toolTip: this.$store.getters.getRegistrationOfficeCode('ES')?.description },
            { field: FilterableField.RegistrationOfficeCode, value: 'FR', comparator: Comparator.Eq, group: GroupType.RegistrationOfficeCodes, image: this.$braendz.registrationOfficeCodeFlag('FR') ?? undefined, title: this.$store.getters.getRegistrationOfficeCode('FR')?.shortDescription ?? this.$t('filter.registrationOfficeCode.fr'), toolTip: this.$store.getters.getRegistrationOfficeCode('FR')?.description },
            { field: FilterableField.RegistrationOfficeCode, value: 'US', comparator: Comparator.Eq, group: GroupType.RegistrationOfficeCodes, image: this.$braendz.registrationOfficeCodeFlag('US') ?? undefined, title: this.$store.getters.getRegistrationOfficeCode('US')?.shortDescription ?? this.$t('filter.registrationOfficeCode.us'), toolTip: this.$store.getters.getRegistrationOfficeCode('US')?.description },

            // BrandStateCategory:
            { field: FilterableField.BrandStateCategory, value: 'Alive', comparator: Comparator.Eq, group: GroupType.BrandStateCategories, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), title: this.$store.getters.getBrandStateCategory('Alive')?.description ?? this.$t('filter.brandStateCategory.alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, selectedDefault: true },
            { field: FilterableField.BrandStateCategory, value: 'New', comparator: Comparator.Eq, group: GroupType.BrandStateCategories, icon: this.$braendz.getBrandStateCategoryIcon('New'), title: this.$store.getters.getBrandStateCategory('New')?.description ?? this.$t('filter.brandStateCategory.new'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, selectedDefault: true },
            { field: FilterableField.BrandStateCategory, value: 'Dead', comparator: Comparator.Eq, group: GroupType.BrandStateCategories, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), title: this.$store.getters.getBrandStateCategory('Dead')?.description ?? this.$t('filter.brandStateCategory.dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color },

            // Brand Types:
            { field: FilterableField.BrandType, value: 'Combined', comparator: Comparator.Eq, group: GroupType.BrandTypes, icon: this.$braendz.getBrandTypeIcon('Combined'), title: this.$t('filter.brandType.combined').toString() },
            { field: FilterableField.BrandType, value: 'Word', comparator: Comparator.Eq, group: GroupType.BrandTypes, icon: this.$braendz.getBrandTypeIcon('Word'), title: this.$t('filter.brandType.word').toString() },
            { field: FilterableField.BrandType, value: 'Figurative', comparator: Comparator.Eq, group: GroupType.BrandTypes, icon: this.$braendz.getBrandTypeIcon('Figurative'), title: this.$t('filter.brandType.figurative').toString() },

            // Nice Classes:
            { field: FilterableField.NiceClasses, value: '1', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('1'), title: `1 - ${ this.$store.getters.getNiceClass('1')?.shortDescription ?? this.$t('filter.niceClass.1') }`, toolTip: this.$store.getters.getNiceClass('1')?.description },
            { field: FilterableField.NiceClasses, value: '2', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('2'), title: `2 - ${ this.$store.getters.getNiceClass('2')?.shortDescription ?? this.$t('filter.niceClass.2') }`, toolTip: this.$store.getters.getNiceClass('2')?.description },
            { field: FilterableField.NiceClasses, value: '3', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('3'), title: `3 - ${ this.$store.getters.getNiceClass('3')?.shortDescription ?? this.$t('filter.niceClass.3') }`, toolTip: this.$store.getters.getNiceClass('3')?.description },
            { field: FilterableField.NiceClasses, value: '4', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('4'), title: `4 - ${ this.$store.getters.getNiceClass('4')?.shortDescription ?? this.$t('filter.niceClass.4') }`, toolTip: this.$store.getters.getNiceClass('4')?.description },
            { field: FilterableField.NiceClasses, value: '5', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('5'), title: `5 - ${ this.$store.getters.getNiceClass('5')?.shortDescription ?? this.$t('filter.niceClass.5') }`, toolTip: this.$store.getters.getNiceClass('5')?.description },
            { field: FilterableField.NiceClasses, value: '6', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('6'), title: `6 - ${ this.$store.getters.getNiceClass('6')?.shortDescription ?? this.$t('filter.niceClass.6') }`, toolTip: this.$store.getters.getNiceClass('6')?.description },
            { field: FilterableField.NiceClasses, value: '7', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('7'), title: `7 - ${ this.$store.getters.getNiceClass('7')?.shortDescription ?? this.$t('filter.niceClass.7') }`, toolTip: this.$store.getters.getNiceClass('7')?.description },
            { field: FilterableField.NiceClasses, value: '8', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('8'), title: `8 - ${ this.$store.getters.getNiceClass('8')?.shortDescription ?? this.$t('filter.niceClass.8') }`, toolTip: this.$store.getters.getNiceClass('8')?.description },
            { field: FilterableField.NiceClasses, value: '9', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('9'), title: `9 - ${ this.$store.getters.getNiceClass('9')?.shortDescription ?? this.$t('filter.niceClass.9') }`, toolTip: this.$store.getters.getNiceClass('9')?.description },
            { field: FilterableField.NiceClasses, value: '10', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('10'), title: `10 - ${ this.$store.getters.getNiceClass('10')?.shortDescription ?? this.$t('filter.niceClass.10') }`, toolTip: this.$store.getters.getNiceClass('10')?.description },
            { field: FilterableField.NiceClasses, value: '11', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('11'), title: `11 - ${ this.$store.getters.getNiceClass('11')?.shortDescription ?? this.$t('filter.niceClass.11') }`, toolTip: this.$store.getters.getNiceClass('11')?.description },
            { field: FilterableField.NiceClasses, value: '12', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('12'), title: `12 - ${ this.$store.getters.getNiceClass('12')?.shortDescription ?? this.$t('filter.niceClass.12') }`, toolTip: this.$store.getters.getNiceClass('12')?.description },
            { field: FilterableField.NiceClasses, value: '13', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('13'), title: `13 - ${ this.$store.getters.getNiceClass('13')?.shortDescription ?? this.$t('filter.niceClass.13') }`, toolTip: this.$store.getters.getNiceClass('13')?.description },
            { field: FilterableField.NiceClasses, value: '14', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('14'), title: `14 - ${ this.$store.getters.getNiceClass('14')?.shortDescription ?? this.$t('filter.niceClass.14') }`, toolTip: this.$store.getters.getNiceClass('14')?.description },
            { field: FilterableField.NiceClasses, value: '15', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('15'), title: `15 - ${ this.$store.getters.getNiceClass('15')?.shortDescription ?? this.$t('filter.niceClass.15') }`, toolTip: this.$store.getters.getNiceClass('15')?.description },
            { field: FilterableField.NiceClasses, value: '16', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('16'), title: `16 - ${ this.$store.getters.getNiceClass('16')?.shortDescription ?? this.$t('filter.niceClass.16') }`, toolTip: this.$store.getters.getNiceClass('16')?.description },
            { field: FilterableField.NiceClasses, value: '17', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('17'), title: `17 - ${ this.$store.getters.getNiceClass('17')?.shortDescription ?? this.$t('filter.niceClass.17') }`, toolTip: this.$store.getters.getNiceClass('17')?.description },
            { field: FilterableField.NiceClasses, value: '18', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('18'), title: `18 - ${ this.$store.getters.getNiceClass('18')?.shortDescription ?? this.$t('filter.niceClass.18') }`, toolTip: this.$store.getters.getNiceClass('18')?.description },
            { field: FilterableField.NiceClasses, value: '19', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('19'), title: `19 - ${ this.$store.getters.getNiceClass('19')?.shortDescription ?? this.$t('filter.niceClass.19') }`, toolTip: this.$store.getters.getNiceClass('19')?.description },
            { field: FilterableField.NiceClasses, value: '20', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('20'), title: `20 - ${ this.$store.getters.getNiceClass('20')?.shortDescription ?? this.$t('filter.niceClass.20') }`, toolTip: this.$store.getters.getNiceClass('20')?.description },
            { field: FilterableField.NiceClasses, value: '21', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('21'), title: `21 - ${ this.$store.getters.getNiceClass('21')?.shortDescription ?? this.$t('filter.niceClass.21') }`, toolTip: this.$store.getters.getNiceClass('21')?.description },
            { field: FilterableField.NiceClasses, value: '22', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('22'), title: `22 - ${ this.$store.getters.getNiceClass('22')?.shortDescription ?? this.$t('filter.niceClass.22') }`, toolTip: this.$store.getters.getNiceClass('22')?.description },
            { field: FilterableField.NiceClasses, value: '23', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('23'), title: `23 - ${ this.$store.getters.getNiceClass('23')?.shortDescription ?? this.$t('filter.niceClass.23') }`, toolTip: this.$store.getters.getNiceClass('23')?.description },
            { field: FilterableField.NiceClasses, value: '24', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('24'), title: `24 - ${ this.$store.getters.getNiceClass('24')?.shortDescription ?? this.$t('filter.niceClass.24') }`, toolTip: this.$store.getters.getNiceClass('24')?.description },
            { field: FilterableField.NiceClasses, value: '25', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('25'), title: `25 - ${ this.$store.getters.getNiceClass('25')?.shortDescription ?? this.$t('filter.niceClass.25') }`, toolTip: this.$store.getters.getNiceClass('25')?.description },
            { field: FilterableField.NiceClasses, value: '26', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('26'), title: `26 - ${ this.$store.getters.getNiceClass('26')?.shortDescription ?? this.$t('filter.niceClass.26') }`, toolTip: this.$store.getters.getNiceClass('26')?.description },
            { field: FilterableField.NiceClasses, value: '27', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('27'), title: `27 - ${ this.$store.getters.getNiceClass('27')?.shortDescription ?? this.$t('filter.niceClass.27') }`, toolTip: this.$store.getters.getNiceClass('27')?.description },
            { field: FilterableField.NiceClasses, value: '28', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('28'), title: `28 - ${ this.$store.getters.getNiceClass('28')?.shortDescription ?? this.$t('filter.niceClass.28') }`, toolTip: this.$store.getters.getNiceClass('28')?.description },
            { field: FilterableField.NiceClasses, value: '29', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('29'), title: `29 - ${ this.$store.getters.getNiceClass('29')?.shortDescription ?? this.$t('filter.niceClass.29') }`, toolTip: this.$store.getters.getNiceClass('29')?.description },
            { field: FilterableField.NiceClasses, value: '30', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('30'), title: `30 - ${ this.$store.getters.getNiceClass('30')?.shortDescription ?? this.$t('filter.niceClass.30') }`, toolTip: this.$store.getters.getNiceClass('30')?.description },
            { field: FilterableField.NiceClasses, value: '31', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('31'), title: `31 - ${ this.$store.getters.getNiceClass('31')?.shortDescription ?? this.$t('filter.niceClass.31') }`, toolTip: this.$store.getters.getNiceClass('31')?.description },
            { field: FilterableField.NiceClasses, value: '32', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('32'), title: `32 - ${ this.$store.getters.getNiceClass('32')?.shortDescription ?? this.$t('filter.niceClass.32') }`, toolTip: this.$store.getters.getNiceClass('32')?.description },
            { field: FilterableField.NiceClasses, value: '33', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('33'), title: `33 - ${ this.$store.getters.getNiceClass('33')?.shortDescription ?? this.$t('filter.niceClass.33') }`, toolTip: this.$store.getters.getNiceClass('33')?.description },
            { field: FilterableField.NiceClasses, value: '34', comparator: Comparator.Eq, group: GroupType.Goods, icon: this.$braendz.getNiceClassIcon('34'), title: `34 - ${ this.$store.getters.getNiceClass('34')?.shortDescription ?? this.$t('filter.niceClass.34') }`, toolTip: this.$store.getters.getNiceClass('34')?.description },
            { field: FilterableField.NiceClasses, value: '35', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('35'), title: `35 - ${ this.$store.getters.getNiceClass('35')?.shortDescription ?? this.$t('filter.niceClass.35') }`, toolTip: this.$store.getters.getNiceClass('35')?.description },
            { field: FilterableField.NiceClasses, value: '36', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('36'), title: `36 - ${ this.$store.getters.getNiceClass('36')?.shortDescription ?? this.$t('filter.niceClass.36') }`, toolTip: this.$store.getters.getNiceClass('36')?.description },
            { field: FilterableField.NiceClasses, value: '37', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('37'), title: `37 - ${ this.$store.getters.getNiceClass('37')?.shortDescription ?? this.$t('filter.niceClass.37') }`, toolTip: this.$store.getters.getNiceClass('37')?.description },
            { field: FilterableField.NiceClasses, value: '38', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('38'), title: `38 - ${ this.$store.getters.getNiceClass('38')?.shortDescription ?? this.$t('filter.niceClass.38') }`, toolTip: this.$store.getters.getNiceClass('38')?.description },
            { field: FilterableField.NiceClasses, value: '39', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('39'), title: `39 - ${ this.$store.getters.getNiceClass('39')?.shortDescription ?? this.$t('filter.niceClass.39') }`, toolTip: this.$store.getters.getNiceClass('39')?.description },
            { field: FilterableField.NiceClasses, value: '40', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('40'), title: `40 - ${ this.$store.getters.getNiceClass('40')?.shortDescription ?? this.$t('filter.niceClass.40') }`, toolTip: this.$store.getters.getNiceClass('40')?.description },
            { field: FilterableField.NiceClasses, value: '41', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('41'), title: `41 - ${ this.$store.getters.getNiceClass('41')?.shortDescription ?? this.$t('filter.niceClass.41') }`, toolTip: this.$store.getters.getNiceClass('41')?.description },
            { field: FilterableField.NiceClasses, value: '42', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('42'), title: `42 - ${ this.$store.getters.getNiceClass('42')?.shortDescription ?? this.$t('filter.niceClass.42') }`, toolTip: this.$store.getters.getNiceClass('42')?.description },
            { field: FilterableField.NiceClasses, value: '43', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('43'), title: `43 - ${ this.$store.getters.getNiceClass('43')?.shortDescription ?? this.$t('filter.niceClass.43') }`, toolTip: this.$store.getters.getNiceClass('43')?.description },
            { field: FilterableField.NiceClasses, value: '44', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('44'), title: `44 - ${ this.$store.getters.getNiceClass('44')?.shortDescription ?? this.$t('filter.niceClass.44') }`, toolTip: this.$store.getters.getNiceClass('44')?.description },
            { field: FilterableField.NiceClasses, value: '45', comparator: Comparator.Eq, group: GroupType.Services, icon: this.$braendz.getNiceClassIcon('45'), title: `45 - ${ this.$store.getters.getNiceClass('45')?.shortDescription ?? this.$t('filter.niceClass.45') }`, toolTip: this.$store.getters.getNiceClass('45')?.description },

            // BrandStates
            { field: FilterableField.BrandState, value: 'Registered', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Registered')?.description ?? 'Registered' },
            { field: FilterableField.BrandState, value: 'Akte vernichtet', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Akte vernichtet')?.description ?? 'Akte vernichtet' },
            { field: FilterableField.BrandState, value: 'Anmeldung abgebrochen', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Anmeldung abgebrochen')?.description ?? 'Anmeldung abgebrochen' },
            { field: FilterableField.BrandState, value: 'Anmeldung eingegangen', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('Anmeldung eingegangen')?.description ?? 'Anmeldung eingegangen' },
            { field: FilterableField.BrandState, value: 'Appeal pending', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Appeal pending')?.description ?? 'Appeal pending' },
            { field: FilterableField.BrandState, value: 'Application', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('Application')?.description ?? 'Application' },
            { field: FilterableField.BrandState, value: 'Application filed', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('Application filed')?.description ?? 'Application filed' },
            { field: FilterableField.BrandState, value: 'Application opposed', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Application opposed')?.description ?? 'Application opposed' },
            { field: FilterableField.BrandState, value: 'Application published', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('Application published')?.description ?? 'Application published' },
            { field: FilterableField.BrandState, value: 'Application refused', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Application refused')?.description ?? 'Application refused' },
            { field: FilterableField.BrandState, value: 'Application under examination', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('Application under examination')?.description ?? 'Application under examination' },
            { field: FilterableField.BrandState, value: 'Application withdrawn', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Application withdrawn')?.description ?? 'Application withdrawn' },
            { field: FilterableField.BrandState, value: 'Demande déposée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('Demande déposée')?.description ?? 'Demande déposée' },
            { field: FilterableField.BrandState, value: 'Demande irrecevable après publication', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Demande irrecevable après publication')?.description ?? 'Demande irrecevable après publication' },
            { field: FilterableField.BrandState, value: 'Demande non publiée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Demande non publiée')?.description ?? 'Demande non publiée' },
            { field: FilterableField.BrandState, value: 'Demande publiée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('Demande publiée')?.description ?? 'Demande publiée' },
            { field: FilterableField.BrandState, value: 'Demande totalement rejetée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Demande totalement rejetée')?.description ?? 'Demande totalement rejetée' },
            { field: FilterableField.BrandState, value: 'Denied', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Denied')?.description ?? 'Denied' },
            { field: FilterableField.BrandState, value: 'Eintragung nicht moeglich', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Eintragung nicht moeglich')?.description ?? 'Eintragung nicht moeglich' },
            { field: FilterableField.BrandState, value: 'Expired', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Expired')?.description ?? 'Expired' },
            { field: FilterableField.BrandState, value: 'Gelöscht', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Gelöscht')?.description ?? 'Gelöscht' },
            { field: FilterableField.BrandState, value: 'In Anmeldung', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('New'), color: brandStateCategoryColors.find(i => i.key === 'New')?.color, title: this.$store.getters.getBrandState('In Anmeldung')?.description ?? 'In Anmeldung' },
            { field: FilterableField.BrandState, value: 'Marke eingetragen', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Marke eingetragen')?.description ?? 'Marke eingetragen' },
            { field: FilterableField.BrandState, value: 'Marke eingetragen, Widerspruchsfrist laeuft', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Marke eingetragen, Widerspruchsfrist laeuft')?.description ?? 'Marke eingetragen, Widerspruchsfrist laeuft' },
            { field: FilterableField.BrandState, value: 'Marke eingetragen, Widerspruchsverfahren laeuft', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Marke eingetragen, Widerspruchsverfahren laeuft')?.description ?? 'Marke eingetragen, Widerspruchsverfahren laeuft' },
            { field: FilterableField.BrandState, value: 'Marke geloescht', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Marke geloescht')?.description ?? 'Marke geloescht' },
            { field: FilterableField.BrandState, value: 'Marque annulée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Marque annulée')?.description ?? 'Marque annulée' },
            { field: FilterableField.BrandState, value: 'Marque ayant fait l\'objet d\'un retrait total', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Marque ayant fait l\'objet d\'un retrait total')?.description ?? 'Marque ayant fait l\'objet d\'un retrait total' },
            { field: FilterableField.BrandState, value: 'Marque ayant fait l\'objet d\'une renonciation totale', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Marque ayant fait l\'objet d\'une renonciation totale')?.description ?? 'Marque ayant fait l\'objet d\'une renonciation totale' },
            { field: FilterableField.BrandState, value: 'Marque déchue', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Marque déchue')?.description ?? 'Marque déchue' },
            { field: FilterableField.BrandState, value: 'Marque enregistrée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Marque enregistrée')?.description ?? 'Marque enregistrée' },
            { field: FilterableField.BrandState, value: 'Marque expirée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Marque expirée')?.description ?? 'Marque expirée' },
            { field: FilterableField.BrandState, value: 'Marque renouvelée', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Marque renouvelée')?.description ?? 'Marque renouvelée' },
            { field: FilterableField.BrandState, value: 'Registration cancellation pending', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Registration cancellation pending')?.description ?? 'Registration cancellation pending' },
            { field: FilterableField.BrandState, value: 'Registration cancelled', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Registration cancelled')?.description ?? 'Registration cancelled' },
            { field: FilterableField.BrandState, value: 'Registration expired', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Registration expired')?.description ?? 'Registration expired' },
            { field: FilterableField.BrandState, value: 'Registration pending', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Registration pending')?.description ?? 'Registration pending' },
            { field: FilterableField.BrandState, value: 'Registration surrendered', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Registration surrendered')?.description ?? 'Registration surrendered' },
            { field: FilterableField.BrandState, value: 'Renouvellement demandé', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Alive'), color: brandStateCategoryColors.find(i => i.key === 'Alive')?.color, title: this.$store.getters.getBrandState('Renouvellement demandé')?.description ?? 'Renouvellement demandé' },
            { field: FilterableField.BrandState, value: 'Ungültige Anmeldung', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Ungültige Anmeldung')?.description ?? 'Ungültige Anmeldung' },
            { field: FilterableField.BrandState, value: 'Registriert', comparator: Comparator.Eq, group: GroupType.BrandStates, icon: this.$braendz.getBrandStateCategoryIcon('Dead'), color: brandStateCategoryColors.find(i => i.key === 'Dead')?.color, title: this.$store.getters.getBrandState('Registriert')?.description ?? 'Registriert' }
        );
        

        // Analyse facets and modify static filters or add additional filters:
        if (this.facets) {
            for(const facetField in this.facets) {
                const facetItems = this.facets[facetField];

                for(const facetItem of facetItems) {

                    // Try to find an existing / static filter, which matches the facet:
                    const existingFilterItem = result.find(filterItem => filterItem.field === facetField && filterItem.value === facetItem.value);

                    // If an existing filter exists, update it:
                    if(existingFilterItem) {

                        // Eventually update title if it is null:
                        existingFilterItem.title = existingFilterItem.title ?? facetItem.value ?? undefined;

                        // Update the counter:
                        existingFilterItem.count = facetItem.count ?? undefined;
                    }
                    else {
                        // The facet is not yet known. Let's create it with default values:
                        let field: FilterableField | null | undefined = facetField as FilterableField;
                        let value = facetItem.value;
                        let title = facetItem.value ?? undefined;
                        let group = GroupType.Unkown;
                        let toolTip: string | undefined = undefined;
                        let icon: string | undefined = undefined;
                        let count: number | undefined = facetItem.count === null ? undefined : facetItem.count;

                        // Override default values:
                        if(facetField === FacetableField.RegistrationOfficeCode) {
                            group = GroupType.RegistrationOfficeCodes;
                            const office = this.$store.getters.getRegistrationOfficeCode(facetItem.value ?? undefined);
                            if (office) {
                                title = `${office.key} (${office.shortDescription})`;
                                toolTip = office.description;
                            }
                        }
                        else if(facetField === FacetableField.BrandType) {
                            group = GroupType.BrandTypes;
                            title = this.$store.getters.getBrandType(facetItem.value ?? undefined)?.description ?? title;
                            icon = this.$braendz.getBrandTypeIcon(facetItem.value ?? undefined);
                        }
                        else if(facetField === FacetableField.BrandStateCategory) {
                            group = GroupType.BrandStateCategories;
                            title = this.$store.getters.getBrandStateCategory(facetItem.value ?? undefined)?.description ?? title;
                            icon = this.$braendz.getBrandStateCategoryIcon(facetItem.value ?? undefined);
                        }
                        else if(facetField === FacetableField.BrandState) {
                            group = GroupType.BrandStates;
                            title = this.$store.getters.getBrandState(facetItem.value ?? undefined)?.description ?? title;
                        }
                        else if (facetField === FacetableField.NiceClasses) {
                            // This is a special case:
                            // If a nice class is not known to our defaults, we don't want to show this value as filter. Let's ignore it.
                            continue;
                        }

                        // If field and value are valid, add this filter item to the filter list:
                        if(field && value) {
                            result.push({ field: field, value: value, count: count, group: group, title: title, toolTip: toolTip, icon: icon })
                        }
                    }
                }
            }
        }

        // Add advanced filters:
        if(this.applicationDateFromFilterItem) {
            result.push(this.applicationDateFromFilterItem);
        }
        if(this.applicationDateUntilFilterItem) {
            result.push(this.applicationDateUntilFilterItem);
        }

        if(this.registrationDateFromFilterItem) {
            result.push(this.registrationDateFromFilterItem);
        }
        if(this.registrationDateUntilFilterItem) {
            result.push(this.registrationDateUntilFilterItem);
        }

        if(this.oppositionPeriodEndDateFromFilterItem) {
            result.push(this.oppositionPeriodEndDateFromFilterItem);
        }
        if(this.oppositionPeriodEndDateUntilFilterItem) {
            result.push(this.oppositionPeriodEndDateUntilFilterItem);
        }

        return result;
    }

    public get niceClassFilters(): FilterItem[] {
        return this.filters.filter(i => i.field === FilterableField.NiceClasses);
    }

    public get applicationDateFromFilterItem(): FilterItem | null {
        let filter = this.selectedFilters.find(i => i.field === FilterableField.ApplicationDate && i.comparator === Comparator.Ge);

        if(this.applicationDateFrom) {
            const title = this.$t('filter.applicationDate.fromTitle', { from: this.$d(this.applicationDateFrom)}).toString();
            const value = this.applicationDateFrom?.toISOString();
            console.log(value);

            if(!filter) {
                filter = { field: FilterableField.ApplicationDate, value: value, comparator: Comparator.Ge, group: GroupType.More, icon: "fa-light fa-calendar-arrow-down", title: title };
            } else {
                filter.title = title;
                filter.value = value;
            }
        }

        return filter ?? null;
    }

    public get applicationDateUntilFilterItem(): FilterItem | null {
        let filter = this.selectedFilters.find(i => i.field === FilterableField.ApplicationDate && i.comparator === Comparator.Le);

        if(this.applicationDateUntil) {
            const title = this.$t('filter.applicationDate.untilTitle', { until: this.$d(this.applicationDateUntil)}).toString();
            const value = this.applicationDateUntil?.toISOString();
            console.log(value);

            if(!filter) {
                filter = { field: FilterableField.ApplicationDate, value: value, comparator: Comparator.Le, group: GroupType.More, icon: "fa-light fa-calendar-arrow-up", title: title };
            } else {
                filter.title = title;
                filter.value = value;
            }
        }

        return filter ?? null;
    }

    public get registrationDateFromFilterItem(): FilterItem | null {
        let filter = this.selectedFilters.find(i => i.field === FilterableField.RegistrationDate && i.comparator === Comparator.Ge);

        if(this.registrationDateFrom) {
            const title = this.$t('filter.registrationDate.fromTitle', { from: this.$d(this.registrationDateFrom)}).toString();
            const value = this.registrationDateFrom?.toISOString();
            console.log(value);

            if(!filter) {
                filter = { field: FilterableField.RegistrationDate, value: value, comparator: Comparator.Ge, group: GroupType.More, icon: "fa-light fa-calendar-arrow-down", title: title };
            } else {
                filter.title = title;
                filter.value = value;
            }
        }

        return filter ?? null;
    }

    public get registrationDateUntilFilterItem(): FilterItem | null {
        let filter = this.selectedFilters.find(i => i.field === FilterableField.RegistrationDate && i.comparator === Comparator.Le);

        if(this.registrationDateUntil) {
            const title = this.$t('filter.registrationDate.untilTitle', { until: this.$d(this.registrationDateUntil)}).toString();
            const value = this.registrationDateUntil?.toISOString();
            console.log(value);

            if(!filter) {
                filter = { field: FilterableField.RegistrationDate, value: value, comparator: Comparator.Le, group: GroupType.More, icon: "fa-light fa-calendar-arrow-up", title: title };
            } else {
                filter.title = title;
                filter.value = value;
            }
        }

        return filter ?? null;
    }

    public get oppositionPeriodEndDateFromFilterItem(): FilterItem | null {
        let filter = this.selectedFilters.find(i => i.field === FilterableField.OppositionPeriodEndDate && i.comparator === Comparator.Ge);

        if(this.oppositionPeriodEndDateFrom) {
            const title = this.$t('filter.oppositionPeriodEndDate.fromTitle', { from: this.$d(this.oppositionPeriodEndDateFrom)}).toString();
            const value = this.oppositionPeriodEndDateFrom?.toISOString();
            console.log(value);

            if(!filter) {
                filter = { field: FilterableField.OppositionPeriodEndDate, value: value, comparator: Comparator.Ge, group: GroupType.More, icon: "fa-light fa-calendar-arrow-down", title: title };
            } else {
                filter.title = title;
                filter.value = value;
            }
        }

        return filter ?? null;
    }

    public get oppositionPeriodEndDateUntilFilterItem(): FilterItem | null {
        let filter = this.selectedFilters.find(i => i.field === FilterableField.OppositionPeriodEndDate && i.comparator === Comparator.Le);

        if(this.oppositionPeriodEndDateUntil) {
            const title = this.$t('filter.oppositionPeriodEndDate.untilTitle', { until: this.$d(this.oppositionPeriodEndDateUntil)}).toString();
            const value = this.oppositionPeriodEndDateUntil?.toISOString();
            console.log(value);

            if(!filter) {
                filter = { field: FilterableField.OppositionPeriodEndDate, value: value, comparator: Comparator.Le, group: GroupType.More, icon: "fa-light fa-calendar-arrow-up", title: title };
            } else {
                filter.title = title;
                filter.value = value;
            }
        }

        return filter ?? null;
    }


    public get groups() {
        return [
            { title: this.$t('filter.groups.registrationOfficeCodes'), type: GroupType.RegistrationOfficeCodes, expanded: true },
            { title: this.$t('filter.groups.brandStateCategories'), type: GroupType.BrandStateCategories, expanded: true },
            { title: this.$t('filter.groups.brandTypes'), type: GroupType.BrandTypes, expanded: true },
            { title: this.$t('filter.groups.goods'), type: GroupType.Goods, expanded: true },
            { title: this.$t('filter.groups.services'), type: GroupType.Services, expanded: true },
            { title: this.$t('filter.groups.brandStates'), type: GroupType.BrandStates, expanded: false },
            { title: this.$t('filter.groups.more'), type: GroupType.More, expanded: true },
        ];
    }

    // Component lifecycle methods:

    public mounted() {
        this.resetSelectedFilters();
    }

    // Event Handler:
    public addApplicationDateFilter(from: Date | null | undefined, until: Date | null | undefined) {
        if(!from) {
            return;
        }

        this.applicationDateFrom = from ?? null;
        this.applicationDateUntil = until ?? null;

        this.$emitter.emit(GlobalEventType.Notified, new Notification(NotificationSeverity.Info, this.$t('filter.applicationDate.addedInfo').toString()));
    }

    public addRegistrationDateFilter(from: Date | null | undefined, until: Date | null | undefined) {
        if(!from) {
            return;
        }

        this.registrationDateFrom = from ?? null;
        this.registrationDateUntil = until ?? null;

        this.$emitter.emit(GlobalEventType.Notified, new Notification(NotificationSeverity.Info, this.$t('filter.registrationDate.addedInfo').toString()));
    }

    public addOppositionPeriodEndDateFilter(from: Date | null | undefined, until: Date | null | undefined) {
        if(!from) {
            return;
        }

        this.oppositionPeriodEndDateFrom = from ?? null;
        this.oppositionPeriodEndDateUntil = until ?? null;

        this.$emitter.emit(GlobalEventType.Notified, new Notification(NotificationSeverity.Info, this.$t('filter.oppositionPeriodEndDate.addedInfo').toString()));
    }

    public selectedNiceClassesChanged(values: FilterItem[]) {
        this.selectedNiceClasses = values;
        this.fireApplyFilters();
    }
    
    // Methods:
    public hasGroup(filters: FilterItem[], groupType: GroupType): boolean {
        return filters.some(i => i.group === groupType);
    }

    public filterByGroupAndSort(filters: FilterItem[], group: GroupType) {
        return filters.filter(i => i.group === group).sort((a, b) => {
            if (isNaN(+a.value) || isNaN(+b.value)) {
                return ('' + a.value).localeCompare(b.value);
            }
            else {
                return +a.value - +b.value;
            }
        });
    }

    public fireSelectedFiltersChanged(value: FilterItem[]): void {
        this.$emit('selectedFiltersChanged', value);
    }

    public getExistingOrNewFilterItemValue(newItem: FilterItem): FilterItem {
        const existing = this.selectedFilters.find(i => i.field === newItem.field && i.value === newItem.value && i.comparator === newItem.comparator);
        return existing ?? newItem;
    }

    public selectedFilterComparer(a: FilterItem, b: FilterItem): boolean {
        if(!a || !b) {
            return false;
        }
        else if(a.field != b.field) {
            return false;
        }
        else if(a.value != b.value) {
            return false;
        }
        else if(a.comparator != b.comparator) {
            return false;
        }
        return true;
    }

    public fireApplyFilters(): void {
        this.$emit('applyFilters');
    }

    public resetSelectedFilters(): void {
        // Delete all selected filters but set the default filters:
        this.selectedFilters = this.filters.filter(i => i.selectedDefault);
    }


}
