













import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator';

@Component({
    model: {
        prop: "model",
        event: "imageChanged"
    }})
export default class ImagePicker extends Vue {
    // Fields
    public dragging = false;

    // Component Properties
    @Prop({ required: true })
    public model!: Blob | null;

    @Prop({required: false})
    public title?: string;

    @Prop({required: false})
    public icon?: string;

    @Prop({required: false})
    public maxPreviewWidth?: number;

    @Prop({required: false})
    public maxPreviewHeight?: number;

    @Prop({ required: false, default: false, type: Boolean })
    public disabled!: boolean;

    // Getter & Setter
    public get image(): Blob | null {
        return this.model;
    }

    public set image(value: Blob | null) {
        this.fireImageChanged(value);
    }

    public drop(e: any) {
        const files = e?.dataTransfer?.files;
        if(files) {
            this.changeFile(files);
        }
    }

    public changeFile(files: FileList | null | undefined) {

        if(!files?.length) {
            this.dragging = false;
            return;
        }

        const file = files[0];

        if(!file) {
            this.dragging = false;
            return;
        }

        if (!file.type.match('image.*')) {
            this.dragging = false;
            return;
        }

        this.image = file;
        this.dragging = false;
    }

    public fireImageChanged(value: Blob | null): void {
        this.$emit('imageChanged', value);
    }

    public getUrl(obj: Blob | MediaSource): string {
        return URL.createObjectURL(obj);
    }

}
