























































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class ConfigureBrandFilterPopup extends Vue {
    // Member
    public selectedTab = 0;
    
        
    // Properties
    @Prop({ required: true })
    public model!: boolean;

    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    // Application Date Tab:
    public applicationDateFilterFormValid = false;
    public selectedApplicationDates: string[] = [];
    
    public get applicationDateFrom(): Date | null {
        return this.fromDate(this.selectedApplicationDates)
    }

    public get applicationDateUntil(): Date | null {
        return this.untilDate(this.selectedApplicationDates)
    }

    public get applicationDateFromLocalized(): string | null {
        const from = this.fromDate(this.selectedApplicationDates);
        return !from ? null : this.$d(from, 'short');
    }

    public get applicationDateUntilLocalized(): string | null {
        const until = this.untilDate(this.selectedApplicationDates);
        return !until ? null : this.$d(until, 'short');
    }

    public addApplicationDateFilter() {
        if (!((this.$refs.applicationDateFilterForm as Vue & { validate: () => boolean }).validate())) {
            return;
        }
        this.$emit('addApplicationDateFilter', this.applicationDateFrom, this.applicationDateUntil);
    }

    // Registration Date Tab:
    public registrationDateFilterFormValid = false;
    public selectedRegistrationDates: string[] = [];
    
    public get registrationDateFrom(): Date | null {
        return this.fromDate(this.selectedRegistrationDates)
    }

    public get registrationDateUntil(): Date | null {
        return this.untilDate(this.selectedRegistrationDates)
    }

    public get registrationDateFromLocalized(): string | null {
        const from = this.fromDate(this.selectedRegistrationDates);
        return !from ? null : this.$d(from, 'short');
    }

    public get registrationDateUntilLocalized(): string | null {
        const until = this.untilDate(this.selectedRegistrationDates);
        return !until ? null : this.$d(until, 'short');
    }

    public addRegistrationDateFilter() {
        if (!((this.$refs.registrationDateFilterForm as Vue & { validate: () => boolean }).validate())) {
            return;
        }
        this.$emit('addRegistrationDateFilter', this.registrationDateFrom, this.registrationDateUntil);
    }

    // OppositionPeriodEnd Date Tab:
    public oppositionPeriodEndDateFilterFormValid = false;
    public selectedOppositionPeriodEndDates: string[] = [];
    
    public get oppositionPeriodEndDateFrom(): Date | null {
        return this.fromDate(this.selectedOppositionPeriodEndDates)
    }

    public get oppositionPeriodEndDateUntil(): Date | null {
        return this.untilDate(this.selectedOppositionPeriodEndDates)
    }

    public get oppositionPeriodEndDateFromLocalized(): string | null {
        const from = this.fromDate(this.selectedOppositionPeriodEndDates);
        return !from ? null : this.$d(from, 'short');
    }

    public get oppositionPeriodEndDateUntilLocalized(): string | null {
        const until = this.untilDate(this.selectedOppositionPeriodEndDates);
        return !until ? null : this.$d(until, 'short');
    }

    public addOppositionPeriodEndDateFilter() {
        if (!((this.$refs.oppositionPeriodEndDateFilterForm as Vue & { validate: () => boolean }).validate())) {
            return;
        }
        this.$emit('addOppositionPeriodEndDateFilter', this.oppositionPeriodEndDateFrom, this.oppositionPeriodEndDateUntil);
    }
    
    // Watcher:
    @Watch('model')
    public onModelChanged(value: boolean) {
        if(value) {
            this.reset();
        }
    }

    // Lifecycle Methods:
    public mounted() {
        this.reset();
    }
    

    // Methods:
    public fromDate(dates: string[]): Date | null {
        if(dates.length < 1) {
            return null;
        } 
        const converted = dates.map(i => new Date(i));
        return converted.reduce((a, b) => a < b ? a : b) ?? null;
    }

    public untilDate(dates: string[]): Date | null {
        if(dates.length < 2) {
            return null;
        } 
        const converted = dates.map(i => new Date(i));
        return converted.reduce((a, b) => a > b ? a : b) ?? null;
    }

    public countDays(from: Date | null, until: Date | null): number | null {
        if(!from || ! until) {
            return null;
        }
        const timeDiff = Math.abs(from.getTime() - until.getTime());
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
        return dayDiff;
    }

    public reset(): void {

        this.selectedTab = 0;
    }

    public close(): void {
        this.visible = false;
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }
}
