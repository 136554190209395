










































































import Vue from 'vue'
import VMenu from 'vuetify'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { FilterItem } from './BrandFilterTreeView.vue';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class NiceClassFilterPopup extends Vue {
    // Member
    public selectedInternal: FilterItem[] = [];
        
    // Properties
    @Prop({ required: true })
    public model!: boolean;

    @Prop({ required: true})
    public items!: FilterItem[];

    @Prop({ required: true})
    public selected!: FilterItem[];

    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }
    
    // Watcher:
    @Watch('model')
    public onModelChanged(value: boolean) {
        if(value) {
            this.reset();
        }
    }

    // Lifecycle Methods:
    public mounted() {
        this.reset();
    }

    // Methods:

    public remove(item: FilterItem) {
      const existing = this.selectedInternal.find(i => i.value == item.value);
      if(existing) {
        const index = this.selectedInternal.indexOf(existing);
        if (index >= 0) {
            const selected = this.selectedInternal;
            selected.splice(index, 1);
            this.selectedInternal = selected;
        }
      }
    }

    public filterNiceClasses(item: FilterItem, queryText: string): boolean {
        return (
            (item.title?.toLocaleLowerCase()?.indexOf(queryText.toLocaleLowerCase()) ?? -1) > -1 ||
            (item.toolTip?.toLocaleLowerCase()?.indexOf(queryText.toLocaleLowerCase()) ?? -1) > -1
        );
    }

    public reset(): void {
        this.selectedInternal = this.selected;
    }

    public close(): void {
        this.visible = false;
        this.$emit('selectedChanged', this.selectedInternal);
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }
}
