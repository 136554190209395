
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Color } from 'vuetify/lib/util/colors';

@Component({})
export default class LoadingArea extends Vue {
    // Properties:

    // Component Properties:
    @Prop({ required: true })
    public label!: string;

    @Prop({ required: false })
    public width?: number;

    @Prop({ required: false })
    public height?: number;

    @Prop({ required: false })
    public minWidth?: number;

    @Prop({ required: false })
    public minHeight?: number;

    @Prop({ required: false })
    public maxWidth?: number;

    @Prop({ required: false })
    public maxHeight?: number;

    // Component Lifecycle:

    // Methods:
}
