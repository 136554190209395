export function blobToBase64Async(blob: Blob): Promise<string | null> {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const dataUrl = reader.result;
            let dataString: string | null = null;

            if((dataUrl as ArrayBuffer).byteLength) {
                const decoder = new TextDecoder();
                dataString = decoder.decode(dataUrl as ArrayBuffer);
            }
            else {
                dataString = dataUrl as string;
            }

            if(dataString) {
                const base64 = (dataUrl as string).split(',')[1];   
                resolve(base64);
            }
            else {
                resolve(null);
            }
        };
        reader.readAsDataURL(blob);
    });
}